<template>
  <div>
    <div v-show="isEmpty || loading" :style="{ width: width + 'px', height: height + 'px', color: '#bdbdbd' }"
      class="flex-row-nowrap-center-center-center">
      <div v-if="isEmpty">无数据</div>
      <n-spin v-if="loading" size="small" />
    </div>
    <div v-show="!isEmpty && !loading" :id="contianerId"></div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, computed, watch, reactive, nextTick } from "vue"
import { v4 as uuid } from 'uuid'
import { Chart } from "@antv/g2"

const props = withDefaults(defineProps<{
  data: Array<any>
  categoryKey?: string,
  valueKey?: string
  width?: number
  height?: number
  valueTitle: string
  loading?: boolean
}>(), {
  categoryKey: "id",
  valueKey: "value",
  width: 300,
  height: 400,
  loading: false
})

const contianerId = uuid()
const isEmpty = computed(() => false)// !props.data || props.data.length == 0)
const state = {
  ready: false,
  chart: {} as any,
  interval: {} as any
}

onMounted(() => {
  const chart = new Chart({
    container: contianerId,
    width: props.width,
    height: props.height
  })
  chart.coordinate({ type: 'theta', innerRadius: 0.25, outerRadius: 0.8 });

  state.interval = chart
    .data(props.data)
    .interval()
    .transform({ type: 'stackY' })
    .encode('y', props.valueKey)
    .encode('color', props.categoryKey)
    .scale('color', {
      palette: 'spectral',
      offset: (t) => t * 0.8 + 0.1,
    })
    .label({
      text: props.valueKey,
      fontWeight: 'bold',
      offset: 4,
    })
    .label({
      text: props.categoryKey,
      position: 'spider',
      connectorDistance: 0,
      fontWeight: 'bold',
      textBaseline: 'bottom',
      textAlign: (d) => (['c', 'sass'].includes(d.id) ? 'end' : 'start'),
      dy: -4,
    })
    .style('radius', 4)
    .style('stroke', '#fff')
    .style('lineWidth', 2)
    .animate('enter', { type: 'waveIn' })
    .legend(false)
  chart.render()
  state.chart = chart
  state.ready = true
})

watch(() => props.data,
  (newData, oldData) => {
    if (!state.ready) {
      return
    }
    nextTick(
      async () => {
        state.chart.data(newData)
        state.chart.render()
      }
    )
  })

</script>
<style scoped></style>
