<template>
  <n-config-provider :theme-overrides="themeOverrides" :locale="bxLocal" :dateLocale="dateLocal">
    <n-message-provider>
      <n-dialog-provider>
        <router-view v-show="!initializing" />
      </n-dialog-provider>
    </n-message-provider>
    <div
      v-if="initializing"
      style="
      width: 100vw;
      height: 100vh;
      background: #fff;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    "
    >
      <n-spin size="large" />
    </div>
  </n-config-provider>
</template>

<script lang="ts" setup>
import {
  computed,
  ref,
} from "vue";
import { zhCN, dateZhCN, GlobalThemeOverrides } from 'naive-ui'
import { useMainStore } from "@/store/main"
import { useEnvStore } from "@/store/EnvStore"
import { useRoute, useRouter } from "vue-router"

const mainStore = useMainStore()
const envStore = useEnvStore()
const router = useRouter();
const route = useRoute()

const initializing = computed(() => mainStore.initializing)

const themeOverrides: GlobalThemeOverrides = {
  common: {
    "primaryColor": "#2593F2FF",
    "primaryColorHover": "#2D86D3FF",
    "primaryColorPressed": "#0D46A0FF",
    "primaryColorSuppl": "#2D86D3FF",
  },
  Layout: {
    "headerColor": "#0847A6FF"
  }
}

const bxLocal = ref(zhCN)
const dateLocal = ref(dateZhCN)

const setMobile = () => {
  envStore.setMobile(document.documentElement.clientWidth <= 768)
  envStore.setSize(document.documentElement.clientWidth, document.documentElement.clientHeight)
}

setMobile()
window.onresize = () => {
  setMobile()
}
envStore.setUerAgent(navigator.userAgent)

</script>