
import request from "@/util/request"
import {  PageResult } from "@/common/model/PageResult"
import { Organization, MyOrganizations, OrganizationFeature } from "@/organization/basic/model/OrganizationModel"
import { ListOrganizationParam } from "@/organization/basic/model/BasicApiModel"
import { ApiResponse } from "@/common/model/ApiModel"

export function getName(entityId: string): Promise<string> {
    return request.get(`/v3/organization/basic/${entityId}/name`)
}

export function findById(entityId: string): Promise<Organization> {
    return request.get(`/v3/organization/basic/${entityId}`)
}

export function list(param: ListOrganizationParam): Promise<PageResult<Organization>> {
    return request.post(`/v3/organization/basic/list`, param)
}

export function insert(entity: Organization): Promise<Organization> {
    return request.post(`/v3/organization/basic`, entity)
}

export function update(entity: Organization): Promise<Organization> {
    return request.put(`/v3/organization/basic/${entity.id}`, entity)
}

export function setFeature(organizationId: string, feature: OrganizationFeature): Promise<void> {
    return request.post(`/v3/organization/basic/${organizationId}/setFeature`, feature)
}

export function updateAvatar(organizationId: string, fileId: string): Promise<ApiResponse> {
    return request.put(`/v3/organization/basic/${organizationId}/avatar`, {
        fileId: fileId
    })
}