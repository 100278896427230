import { PageResult } from "@/common/model/PageResult"
import { User, UserFeature } from "@/user/model/UserModel"
import request from "@/util/request"
import { ListUserParam } from "@/user/model/UserApiModel"
import { ApiResponse } from "@/common/model/ApiModel"
import { BatchResult } from "@/common/model/BatchResult"

export function getUser(id: string, code: string, state: string): Promise<User> {
  return request.get(`/v3/user/${id}`, {
    params: {
      state,
      code
    }
  })
}

export function getMyUser(): Promise<User> {
  return request.get(`/v3/user/me`)
}

export function getMyFeature(): Promise<UserFeature> {
  return request.get(`/v3/user/myFeature`)
}

export function list(param: ListUserParam): Promise<PageResult<User>> {
  return request.post("/v3/user/list", param)
}

export function updateName(id: string, name: string): Promise<ApiResponse> {
  return request.put(`/v3/user/${id}/name`, { name })
}

export function updateEmail(userId: string, code: string, email: string): Promise<ApiResponse> {
  return request.put(`/v3/user/${userId}/email`, {
    code: code,
    data: email
  })
}

export function updatePhone(userId: string, code: string, phone: string): Promise<ApiResponse> {
  return request.put(`/v3/user/${userId}/phone`, {
    code: code,
    data: phone
  })
}

export function updateAvatar(userId: string, fileId: string): Promise<ApiResponse> {
  return request.put(`/v3/user/${userId}/avatar`, {
    fileId: fileId
  })
}

export function updateGender(id: string, gender: string): Promise<ApiResponse> {
  return request.put(`/v3/user/${id}/gender`, { gender })
}

export function updateIdNumber(id: string, idNumber: string): Promise<ApiResponse> {
  return request.put(`/v3/user/${id}/idNumber`, { idNumber })
}


export function fetchByPhone(phones: Array<string>): Promise<BatchResult<string, User>> {
  return request.post(`/v3/user/fetchByPhone`, phones)
}