import { defineStore } from "pinia"
import { reactive, ref, computed } from "vue"
import { getName as getOrganizationName } from "@/organization/basic/api/BasicApi"
import message from "@/util/message"

export const useOrganizationStore = defineStore("organization", () => {
  const state = reactive({
    map: new Map<string, string>()
  })

  const getName = async (id: string): Promise<string> => {
    if (state.map.has(id)) {
      return state.map.get(id) ?? ""
    } else {
      try {
        const name = await getOrganizationName(id)
        state.map.set(id, name)
        return name
      } catch (error: any) {
        message.networkError(error)
        return ""
      }
    }

  }

  return {
    getName
  }
})