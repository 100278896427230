<template>
  <div class="ec-search-field">
    <n-popover ref="filterPanel" trigger="click" placement="bottom" :delay="0" @update:show="onFilterToggle">
      <template #trigger>
        <div class="flex-row-nowrap-center-center-center filter-container">
          <ec-icon name="common/filter" :size="15" color="#505050" highlightColor="#2593f2" clickable></ec-icon>
          <div v-if="selectedOptions.length == 0 && placeholder.length > 0" style=" margin-left: 4px; color: #c2c2c2;">{{
            placeholder }}</div>
          <div class="flex-row-nowrap-start-start-center">
            <template v-for="option in selectedOptions">
              <n-tag style="margin-left: 4px; cursor: pointer;">{{ option.text }}</n-tag>
            </template>
            <div v-if="hasMore" style="margin-left: 4px;">等</div>
          </div>
        </div>
      </template>
      <div class="container">
        <div class="flex-row-nowrap-start-start-center search-container">
          <n-input v-model:value="state.keyword" placeholder="关键字过滤" clearable></n-input>
        </div>
        <n-scrollbar style="max-height: 300px;">
          <div class="option-container">
            <div class="option" v-for="opt in showOptions" key="id">
              <n-checkbox :checked="opt.checked" @update:checked="(checked: boolean) => onChecked(opt, checked)">
                <ec-highlight-label :text="opt.text" :keywords="state.keyword"></ec-highlight-label>
              </n-checkbox>
            </div>
            <div v-if="showOptions.length == 0" class="ec-hint" style="padding-top: 8px; padding-bottom: 4px;">
              找不到包含该关键字的选项
            </div>
          </div>
        </n-scrollbar>
        <div style="background: rgba(31, 34, 37, 0.1); height: 1px; width: 100%; margin-top:8px;"></div>
        <div class="flex-row-nowrap-end-center-center action-container">
          <n-button size="small" style="margin-right: 8px;" @click="onReset">全部清除</n-button>
          <n-button size="small" type="primary" @click="onFilter">确认</n-button>
        </div>
      </div>
    </n-popover>
  </div>
</template>
<script lang="ts" setup>
import {
  computed,
  reactive,
  ref,
  watch
} from "vue"
import { Option } from "@/components/model/Option"
import { NPopover } from "naive-ui"

const props = withDefaults(defineProps<{
  multiple?: boolean,
  options: Array<Option>,
  selectedIds: Array<string>,
  placeholder?: string
}>(), {
  placeholder: ""
})
const emits = defineEmits<{
  (e: "update:selectedIds", selectedIds: Array<string>): void,
  (e: "search"): void,
}>()

const filterPanel = ref<InstanceType<typeof NPopover> | null>(null)

const state = reactive({
  selectedOption: [] as Array<Option>,
  options: props.options,
  keyword: ""
})

const showOptions = computed(() => {
  if (state.keyword.length == 0) {
    return state.options
  } else {
    return state.options.filter(opt => opt.text.indexOf(state.keyword) >= 0)
  }
})

const onChecked = (opt: Option, checked: boolean) => {
  if (props.multiple) {
    opt.checked = checked
    let ids = [] as Array<string>
    let options = [] as Array<Option>
    for (const t of state.options) {
      if (t.checked) {
        ids.push(t.id)
        options.push(t)
      }
    }
    state.selectedOption = options
    emits("update:selectedIds", ids)
    return
  } else {
    for (const t of props.options) {
      t.checked = false
    }
    if (checked) {
      opt.checked = true
      state.selectedOption = [opt]
      emits("update:selectedIds", [opt.id])
    } else {
      state.selectedOption = []
      emits("update:selectedIds", [])
    }
  }
}

const onFilter = () => {
  filterPanel.value?.setShow(false)
  emits("search")
}

const onFilterToggle = (show: boolean) => {
  if (!show) {
    onFilter()
  }
}

const onReset = () => {
  for (const t of state.options) {
    t.checked = false
  }
  state.selectedOption = []
  emits("update:selectedIds", [])
}

const refreshCheckedState = () => {
  let selecteds = [] as Array<Option>
  for (const opt of state.options) {
    opt.checked = props.selectedIds.indexOf(opt.id) >= 0
    if (opt.checked) {
      selecteds.push(opt)
    }
  }
  state.selectedOption = selecteds
}

watch(
  () => props.options,
  (value, oldValue) => {
    if (value) {
      state.options = JSON.parse(JSON.stringify(value))
      refreshCheckedState()
    } else {
      state.options = []
    }
  }
)

watch(
  () => props.selectedIds,
  (value, oldValue) => {
    if (value) {
      refreshCheckedState()
    } else {
      state.selectedOption = []
    }
  }
)

const selectedOptions = computed(() => {
  const result = [] as Array<Option>
  for (let index = 0; index < Math.min(state.selectedOption.length, 3); index++) {
    result.push(state.selectedOption[index])
  }
  return result
})

const hasMore = computed(() => {
  return state.selectedOption.length > 3
})

refreshCheckedState()

</script>
<style scoped>
.filter-container {
  background-color: #fefefe;
  cursor: pointer;
  height: 34px;
  padding-left: 8px;
  padding-right: 8px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #e0e0e0;
}

.container {
  width: 400px;
}

.search-container {
  padding-bottom: 4px;
}

.option-container {}

.option {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}

.action-container {
  width: 400px;
  margin-top: 8px;
}

.button {
  height: 32px;
}
</style>
