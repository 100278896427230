<template>
  <n-input :value="data" @update:value="onTextInput"></n-input>
</template>
<script lang="ts" setup>
import { watch } from "vue"
import formater from "@/util/NumberFormater"

const props = withDefaults(defineProps<{
  data: string
  format?: string
  editable?: boolean
}>(), {
  data: "",
  format: "integer",
  editable: true
})

const emits = defineEmits<{
  "update:data": [value: string]
}>()

const onTextInput = (text: string) => {
  if (!props.editable) {
    return
  }
  if (props.format == "integer") {
    emits("update:data", formater.formatIntegerInput(text))
  } else if (props.format == "decimal") {
    emits("update:data", formater.formatDecimalInput(text))
  }
}

watch(() => props.format,
  (newF, oldF) => {
    if (oldF == "decimal" && newF == "integer") {
      emits("update:data", formater.formatIntegerInput(props.data))
    }
  })

</script>
<style scoped></style>