<template>
  <div class="ec-table-container">
    <slot name="header"></slot>
    <n-data-table v-if="!networkError" ref="pcTable" class="flex-grow-height" flexHeight :loading="loading"
      :columns="columns" :data="data" :cascade="cascade" :size="size" :rowProps="rowProps" :rowKey="rowKey"
      :scrollX="state.scrollX" :expandedRowKeys="expandedRowKeys" @update:expandedRowKeys="onExpandedRowKeys"
      :checkedRowKeys="checkedRowKeys" @update:checkedRowKeys="onCheckedRowKeys"></n-data-table>
    <div v-if="showPagination && !networkError" class="flex-row-nowrap-end-start-start" style="margin-top:12px">
      <n-pagination v-model:page="pagination.page" :pageCount="pagination.pageCount" @update:page="onPage"></n-pagination>
    </div>
    <div v-if="networkError" class="flex-row-nowrap-center-center-center" style="width: 100%; padding-top: 100px;">
      <n-result status="500" title="网络错误" description="服务器似乎在开小差，点击按钮重试">
        <template #footer>
          <n-button type="primary" @click="emits('retry')">重试</n-button>
        </template>
      </n-result>
    </div>

  </div>
</template>
<script lang="ts" setup>
import {
  reactive,
  computed,
  watch,
  ref
} from "vue"
import { calScrollX, useTableStyle } from "@/util/TableHelper"
import { NDataTable } from "naive-ui"

const props = withDefaults(defineProps<{
  columns: Array<any>
  data: Array<any>
  flexHeight?: boolean
  loading?: boolean,
  rowProps?: any
  rowKey?: any
  expandedRowKeys?: Array<string>
  checkedRowKeys?: Array<string>
  cascade?: boolean
  size?: string
  pagination?: any
  networkError?: boolean
}>(), {
  networkError: false,
  size: "small"
})
const emits = defineEmits<{
  (e: "update:expandedRowKeys", expandedKeys: Array<string>): void
  (e: "update:checkedRowKeys", expandedKeys: Array<string>): void
  (e: "update:page", page: number): void
  (e: "retry"): void
}>()

const pcTable = ref<InstanceType<typeof NDataTable> | null>(null)

const state = reactive({
  scrollX: 1024 as number | undefined
})

const showPagination = computed(() => {
  return props.pagination && props.pagination.page && props.pagination.page > 0
})

const onExpandedRowKeys = (expandedKeys: Array<string>) => {
  emits("update:expandedRowKeys", expandedKeys)
}

const onCheckedRowKeys = (checkedKeys: Array<string>) => {
  emits("update:checkedRowKeys", checkedKeys)
}

const onPage = (page: number) => {
  emits("update:page", page)
}

state.scrollX = calScrollX(props.columns)

watch(
  () => props.columns,
  (n, o) => {
    state.scrollX = calScrollX(n)
  }
)

watch(
  () => props.data,
  () => {
    pcTable.value?.scrollTo(0, 0)
  }
)

</script>
<style scoped>
.ec-table-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%
}
</style>
