import {
  startsWith,
  endsWith
} from "./StringUtils"
import * as Sentry from "@sentry/vue"

export class MimeDetector {

  async detectFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileSlice = file.slice(0, 100)
      const reader = new FileReader()
      reader.onload = (e) => {
        const af = e?.target?.result ?? undefined
        if (af) {
          resolve(this.detect(af as ArrayBufferLike, file.name))
        } else {
          resolve("")
        }        
      }
      reader.onerror = () => { resolve("") }
      reader.onabort = () => { resolve("") }
      reader.readAsArrayBuffer(fileSlice)
    })
  }

  readBytes(dataView: DataView, offset: number, length: number): string {
    let bytes = ""
    const count = length + offset
    for (let index = offset; index < count; index++) {
      const byte = Number(dataView.getUint8(index)).toString(16).toUpperCase().padStart(2, "0")
      bytes = bytes + byte
    }
    return bytes
  }

  detect(buffer: ArrayBufferLike, fileName: string): string {
    const dataView = new DataView(buffer)    
    const first8Bytes = this.readBytes(dataView, 0, 8)
    switch (first8Bytes) {
      case "D0CF11E0A1B11AE1":
        return this.detectMcb(dataView, fileName)
      default:
        break
    }

    const first6Bytes = this.readBytes(dataView, 0, 6)
    switch (first6Bytes) {
      case "377ABCAF271C":
        return "application/x-7z-compressed"
      default:
        break
    }

    const first4Bytes = this.readBytes(dataView, 0, 4)
    switch (first4Bytes) {
      case '504B0304':
      case '504B0506':
      case '504B0708':
        return this.detectZip(dataView, fileName)
      case "D0CF11E0":
        return this.detectPpt(dataView, fileName)
      case "52617221":
        return this.detectRar(dataView, fileName)
      default:
        Sentry.captureMessage(`sniff mime failed, fileName=${fileName}, bytes=${this.readBytes(dataView, 0, 20)}`)
        return ""
    }
  }

  detectZip(dataView: DataView, fileName: string): string {
    const first10bytes = this.readBytes(dataView, 4, 10)

    switch (first10bytes) {
      case "14000600080000002100":
      case "0A0000000000874EE240":
      case "14000000000000000000":
        if (endsWith(fileName, ".pptx") || endsWith(fileName, ".ppt")) {
          return "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        } else if (endsWith(fileName, ".docx")) {
          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        } else if (endsWith(fileName, ".xlsx")) {
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        } else if (endsWith(fileName, ".xlsm")) {
          return "application/vnd.ms-excel.sheet.macroEnabled.12"
        } else  {
          Sentry.captureMessage(`zip not found, fileName=${fileName}, first 10 bytes=${first10bytes}`)
          return ""
        }
      default:
        break
    }

    const first6bytes = this.readBytes(dataView, 4, 6)

    switch (first6bytes) {
      case "140006000800":
      case "0A0000000000":
      case "140000080000":
      case "140008080800":
        if (endsWith(fileName, ".pptx") || endsWith(fileName, ".ppt")) {
          return "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        } else if (endsWith(fileName, ".docx")) {
          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        } else if (endsWith(fileName, ".xlsx")) {
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        } else if (endsWith(fileName, ".enbx")) {
          return "application/seewo.enbx"
        } else {
          Sentry.captureMessage(`zip not found, fileName=${fileName}, first 6 bytes=${first6bytes}`)
          return ""
        }
      case "140006080800":
        if (this.endsWith(fileName, ".ndpx")) {
          return "application/ppt101.ndpx"
        } else {
          Sentry.captureMessage(`zip not found, fileName=${fileName}, first 6 bytes=${first6bytes}`)
          return ""
        }
      default:
        Sentry.captureMessage(`zip not found, fileName=${fileName}, first 6 bytes=${first6bytes}`)
        return ""
    }


  }

  detectPpt(dataView: DataView, fileName: string): string {
    const bytes = this.readBytes(dataView, 4, 12)

    switch (bytes) {
      case "A1B11AE10000000000000000":
        return "application/vnd.ms-powerpoint"
      default:
        Sentry.captureMessage(`ppt not found, fileName=${fileName}, bytes=${bytes}`)
        return ""
    }
  }

  detectRar(dataView: DataView, fileName: string): string {
    const bytes = this.readBytes(dataView, 4, 4)
    if (startsWith(bytes, "1A0700") || startsWith(bytes, "1A070100")) {
      return "application/x-rar-compressed"
    } else {
      Sentry.captureMessage(`rar not founddd, fileName=${fileName}, bytes=${bytes}`)
      return ""
    }
  }

  detectMcb(dataView: DataView, fileName: string): string {
    const bytes = this.readBytes(dataView, 4, 4)
    if (endsWith(fileName, ".ppt")) {
      return "application/vnd.ms-powerpoint"
    } else if (endsWith(fileName, ".xls")) {
      return "application/vnd.ms-excel"
    } else if (endsWith(fileName, ".et")) {
      return "application/vnd.ms-excel"
    } else if (this.endsWith(fileName, ".doc")) {
      return "application/msword"
    } else {
      Sentry.captureMessage(`mcb not found, fileName=${fileName}, bytes=${bytes}`)
      return ""
    }
  }
}