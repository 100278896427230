import * as Sentry from "@sentry/vue"

export const replay = new Sentry.Replay({
  maskAllText: true,
  blockAllMedia: true
})




export function initSentry(app: any, router: any) {
  const envValue = import.meta.env.VITE_ENV
  // console.log("init sentry, env=" + envValue)
  Sentry.init({
    app,
    dsn: "https://4fa65b21b6a8a7c342896b586f2621eb@monitor.8jintech.com/2",
    environment: envValue,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "bixi.8jintech.com", "8jinyun.com"],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      replay,
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

