import { defineStore } from "pinia"
import { reactive, ref, computed } from "vue"
import * as Sentry from "@sentry/vue"

export const useEnvStore = defineStore("env", () => {
  const state = reactive({
    ua: "",
    isMobile: false,
    size: {
      panelHeight: 1080 - 64 - 32, //减去header+footer高度
      width: 1920,
      height: 1080,
    },
  })

  const isMobile = computed(() => state.isMobile)
  const size = computed(() => state.size)
  const ua = computed(() => state.ua)
  const wechatWork = computed(() => /wxwork/i.test(state.ua.toLowerCase()))


  const feature = computed(() => {
    return {
      wechatWork: false
    }
  })

  const setMobile = (isMobile: boolean) => {
    state.isMobile = isMobile
  }

  const setSize = (width: number, height: number) => {
    state.size = {
      panelHeight: height - 64 - 32, //减去header+footer高度
      width: width,
      height: height
    }
    Sentry.setTag("screen.width", `${width}`)
    Sentry.setTag("screen.height", `${height}`)
  }

  const setUerAgent = (userAgent: string) => {
    state.ua = userAgent
  }

  const isApp = computed(() => {
    if (window.BxJsBridge) {
      return true
    } else {
      return false
    }
  })


  return {
    feature,
    isMobile,
    size,
    ua,
    wechatWork,
    isApp,
    setMobile,
    setSize,
    setUerAgent,
  }

})