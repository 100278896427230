import { startsWith } from "@/util/StringUtils"

const catchImport = (error: any) => {
  const isChunkLoadFailed = startsWith(error.message, "Loading chunk")
    || startsWith(error.message, "Loading CSS chunk")
    || startsWith(error.message, "Failed to fetch dynamically")
  if (isChunkLoadFailed) {
    location.reload()
  }
}

const Routes = {
  Auth: {
    Login: {
      path: "/login",
      name: "Login",
      component: () => import("@/auth/view/Login.vue").catch(catchImport),
      single: true,
    },
    Join: {
      path: "/join",
      name: "Join",
      component: () => import("@/auth/view/Join.vue").catch(catchImport),
      single: true,
    },
    ForgetPassword: {
      path: "/forget-password",
      name: "ForgetPassword",
      component: () => import("@/auth/view/ForgetPassword.vue").catch(catchImport),
      single: true,
    },
  },
  Common: {
    License: {
      path: "/license",
      name: "License",
      component: () => import("@/settings/view/License.vue").catch(catchImport),
      single: true,
    },
    Settings: {
      path: "/settings",
      name: "Settings",
      component: () => import("@/settings/view/Settings.vue").catch(catchImport),
      props: true,
      requireAuth: true,
    },
    NotFound: {
      path: "/404",
      name: "404",
      component: () => import("@/common/view/404.vue").catch(catchImport),
      single: true
    },
    Home: {
      path: "/",
      name: "Home",
      component: () => import("@/common/view/home/Home.vue").catch(catchImport),
      single: true,
      requireAuth: true,
    },
  },
  User: {
    List: {
      path: "/user/list",
      name: "UserList",
      component: () => import("@/user/view/list/UserList.vue").catch(catchImport),
      props: true,
      requireAuth: true,
    },
    Detail: {
      path: "/user/:userId",
      name: "UserDetail",
      component: () => import("@/user/view/detail/UserDetail.vue").catch(catchImport),
      build(userId: string) { return `/user/${userId}` },
      props: true,
      requireAuth: true,
    },
  },
  Organization: {
    Basic: {
      List: {
        path: "/organization/list",
        name: "OrganizationList",
        component: () => import("@/organization/basic/view/list/OrganizationList.vue").catch(catchImport),
        requireAuth: true,
      }, 
      Detail: {
        path: "/organization/:organizationId",
        name: "OrganizationDetail",
        component: () => import("@/organization/basic/view/detail/OrganizationDetail.vue").catch(catchImport),
        build(organizationId: string) { return `/organization/${organizationId}` },
        requireAuth: true,
        props: true,
      },
    },
    Member: {
      List: {
        path: "/organization/:organizationId/member",
        name: "OrganizationMemberList",
        component: () => import("@/organization/basic/view/member/list/OrganizationMemberList.vue").catch(catchImport),
        build(organizationId: string) { return `/organization/${organizationId}/member` },
        props: true,
        requireAuth: true,
      },
      InvitationList: {
        path: "/organization/:organizationId/invitation",
        name: "OrganizationInvitationList",
        component: () => import("@/organization/basic/view/member/invitationList/OrganizationInvitationList.vue").catch(catchImport),
        build(organizationId: string) { return `/organization/${organizationId}/invitation` },
        props: true,
        requireAuth: true,
      },
    },
    Department: {
      List: {
        path: "/organization/:organizationId/department",
        name: "Department",
        component: () => import("@/organization/department/view/list/DepartmentList.vue").catch(catchImport),
        build(organizationId: string) { return `/organization/${organizationId}/department` },
        props: true,
        requireAuth: true,
      }
    },
    Role: {
      List: {
        path: "/organization/:organizationId/role",
        name: "OrganizationRole",
        component: () => import("@/organization/role/view/list/RoleList.vue").catch(catchImport),
        build(organizationId: string) { return `/organization/${organizationId}/role` },
        props: true,
        requireAuth: true,
      },
      Detail: {
        path: "/organization/role/:roleId",
        name: "RoleDetail",
        component: () => import("@/organization/role/view/detail/RoleDetail.vue").catch(catchImport),
        build(roleId: string) { return `/organization/role/${roleId}` },
        props: true,
        requireAuth: true,
      },
    },
    Structure: {
      path: "/organization/:organizationId/structure",
      name: "OrganizationStructure",
      component: () => import("@/organization/structure/view/list/StructureList.vue").catch(catchImport),
      build(organizationId: string) { return `/organization/${organizationId}/structure` },
      props: true,
      requireAuth: true,
    },
    Statics: {
      path: "/organization/:organizationId/statics",
      name: "OrganizationStatics",
      component: () => import("@/organization/statics/view/OrganizationStatics.vue").catch(catchImport),
      build(organizationId: string) { return `/organization/${organizationId}/statics` },
      requireAuth: true,
      props: true,
    },
  },
  Resource: {
    ResourceCenter: {
      path: "/resource/center",
      name: "ResourceCenter",
      component: () => import("@/resource/node/view/resourceCenter/ResourceCenter.vue").catch(catchImport),
      props: true,
      requireAuth: true,
      isHome: true,
    },
    Organization: {
      CatalogManagement: {
        path: "/resource/organization/:targetId/catalog",
        name: "OrganizationCatalogList",
        component: () => import("@/resource/node/view/catalogList/CatalogList.vue").catch(catchImport),
        build(targetId: string) { return `/resource/organization/${targetId}/catalog` },
        props: true,
        requireAuth: true,
      },
      TagManagement: {
        path: "/resource/organization/:organizationId/tag",
        name: "OrganizationTagManagement",
        component: () => import("@/resource/tag/view/organization/tagDimension/TagDimensionList.vue").catch(catchImport),
        build(organizationId: string) { return `/resource/organization/${organizationId}/tag` },
        props: true,
        requireAuth: true,
      },
      ShareList: {
        path: "/resource/organization/:organizationId/share/list",
        name: "ShareList",
        component: () => import("@/resource/share/view/list/ShareRecordList.vue").catch(catchImport),
        build(organizationId: string) { return `/resource/organization/${organizationId}/share/list` },
        props: true,
        requireAuth: true,
      },
    },
    Personal: {
      CatalogManagement: {
        path: "/resource/personal/catalog",
        name: "PersonalCatalogList",
        component: () => import("@/resource/node/view/catalogList/CatalogList.vue").catch(catchImport),
        props: true,
        requireAuth: true,
      },
      TagManagement: {
        path: "/resource/personal/tag",
        name: "PersonalTagManagement",
        component: () => import("@/resource/tag/view/personal/tagDimension/TagDimensionList.vue").catch(catchImport),
        props: true,
        requireAuth: true,
      },
    }
  },
  Archive: {
    Permission: {
      List: {
        path: "/archive/organization/:organizationId/permission/roles",
        name: "PermissionRoleList",
        component: () => import("@/archive/permission/view/list/PermissionRoleList.vue").catch(catchImport),
        build(organizationId: string) { return `/archive/organization/${organizationId}/permission/roles` },
        props: true,
        requireAuth: true,
      },
      Detail: {
        path: "/archive/permission/role/:permissionRoleId",
        name: "PermissionRoleDetail",
        component: () => import("@/archive/permission/view/detail/PermissionRoleDetail.vue").catch(catchImport),
        build(permissionRoleId: string) { return `/archive/permission/role/${permissionRoleId}` },
        props: true,
        requireAuth: true,
      }
    },
    ArchiveCenter: {
      path: "/archive/center/:organizationId",
      name: "ArchiveCenter",
      component: () => import("@/archive/node/view/archiveCenter/ArchiveCenter.vue").catch(catchImport),
      build(organizationId: string) { return `/archive/center/${organizationId}` },
      props: true,
      requireAuth: true,
      isHome: true,
    },
  },
  Flow: {
    FlowCenter: {
      path: "/flow/center",
      name: "FlowCenter",
      component: () => import("@/flow/view/FlowCenter.vue").catch(catchImport),
      props: true,
      requireAuth: true,
      isHome: true,
    },
    Management: {
      path: "/flow/management/:organizationId",
      name: "FlowManagement",
      build(organizationId: string) { return `/flow/management/${organizationId}` },
      component: () => import("@/flow/view/management/FlowManagement.vue").catch(catchImport),
      props: true,
      requireAuth: true,
    },
    FlowDetail: {
      path: "/flow/:flowId",
      name: "FlowDetail",
      component: () => import("@/flow/view/editor/FlowDetail.vue").catch(catchImport),
      build(flowId: string) { return `/flow/${flowId}` },
      props: true,
      requireAuth: true,
    },
    InstanceDetail: {
      path: "/flow/:flowId/instance/:instanceId",
      name: "InstanceDetail",
      component: () => import("@/flow/view/instance/InstanceDetail.vue").catch(catchImport),
      build(flowId: string, instanceId: string) { return `/flow/${flowId}/instance/${instanceId}` },
      props: true,
      requireAuth: true,
    },
    Statics: {
      path: "/flow/:flowId/statics",
      name: "FlowStatics",
      component: () => import("@/flow/view/statics/FlowStatics.vue").catch(catchImport),
      build(flowId: string) { return `/flow/${flowId}/statics` },
      props: true,
      requireAuth: true,
    },
    Form: {
      Statics: {
        path: "/flow/form/:flowId/statics",
        name: "FormStatics",
        component: () => import("@/flow/form/view/statics/FormStatics.vue").catch(catchImport),
        build(flowId: string) { return `/flow/form/${flowId}/statics` },
        props: true,
        requireAuth: true,
      },
      VerifyForm: {
        path: "/flow/form/verify/:flowId",
        name: "VerifyForm",
        component: () => import("@/flow/view/verifyForm/VerifyForm.vue").catch(catchImport),
        build(flowId: string) { return `/flow/form/verify/${flowId}` },
        props: true,
        single: true
      },
      //TODO: form应该改用同一个url格式，在vue内判断类型
      PublicForm: {
        path: "/flow/form/public/:flowId",
        name: "PublicForm",
        component: () => import("@/flow/view/publicForm/PublicForm.vue").catch(catchImport),
        build(flowId: string) { return `/flow/form/public/${flowId}` },
        props: true,
        single: true
      }
    }
  },
  Evaluation: {
    Management: {
      path: "/evaluation/management/:organizationId",
      name: "EvaluationManagement",
      component: () => import("@/evaluation/view/management/EvaluationManagement.vue").catch(catchImport),
      build(organizationId: string) { return `/evaluation/management/${organizationId}` },
      props: true,
      requireAuth: true,
      isHome: true,
    },
    Detail: {
      path: "/evaluation/:evaluationId",
      name: "EvaluationDetail",
      component: () => import("@/evaluation/view/detail/EvaluationDetail.vue").catch(catchImport),
      build(evaluationId: string) { return `/evaluation/${evaluationId}` },
      props: true,
      requireAuth: true,
      isHome: true,
    },
    Center: {
      path: "/evaluation/center",
      name: "EvaluationCenter",
      component: () => import("@/evaluation/view/center/EvaluationCenter.vue").catch(catchImport),
      build() { return `/evaluation/center` },
      props: true,
      requireAuth: true,
      isHome: true,
    },
    Submit: {
      path: "/evaluation/:evaluationInstanceId/submit",
      name: "EvaluationSubmit",
      component: () => import("@/evaluation/view/submit/EvaluationSubmit.vue").catch(catchImport),
      build(evaluationInstanceId: string) { return `/evaluation/${evaluationInstanceId}/submit` },
      props: true,
      requireAuth: true,
      isHome: true,
    },
    Review: {
      path: "/evaluation/:evaluationInstanceId/review",
      name: "EvaluationReview",
      component: () => import("@/evaluation/view/review/EvaluationReview.vue").catch(catchImport),
      build(evaluationInstanceId: string) { return `/evaluation/${evaluationInstanceId}/review` },
      props: true,
      requireAuth: true,
      isHome: true,
    },
    Judge: {
      path: "/evaluation/:evaluationInstanceId/judge",
      name: "EvaluationJudge",
      component: () => import("@/evaluation/view/judge/EvaluationJudge.vue").catch(catchImport),
      build(evaluationInstanceId: string) { return `/evaluation/${evaluationInstanceId}/judge` },
      props: true,
      requireAuth: true,
      isHome: true,
    },
    InstanceDetail: {
      path: "/evaluation/instance/:instanceId",
      name: "EvaluationInstanceDetail",
      component: () => import("@/evaluation/view/instanceDetail/EvaluationInstanceDetail.vue").catch(catchImport),
      build(instanceId: string) { return `/evaluation/instance/${instanceId}` },
      props: true,
      requireAuth: true,
      isHome: true,
    },
  },
  Manage: {
    Organization: {
      InitiationManagement: {
        path: "/manage/organization/initiation",
        name: "NodeManagement",
        component: () => import("@/manage/organization/view/InitiationManagement.vue").catch(catchImport),
        requireAuth: true,
      },
      ImportOrganization: {
        path: "/manage/organization/import",
        name: "ImportOrganization",
        component: () => import("@/manage/organization/view/ImportOrganization.vue").catch(catchImport),
        requireAuth: true,
      },
    },
    Storage: {
      path: "/manage/storage",
      name: "StorageManagement",
      component: () => import("@/manage/storage/view/StorageManagement.vue").catch(catchImport),
      requireAuth: true,
    },
    CacheManagement: {
      path: "/manage/cache",
      name: "CacheManagement",
      component: () => import("@/manage/view/CacheManagement.vue").catch(catchImport),
      requireAuth: true,
    },
    SystemMigration: {
      path: "/manage/systemMigration",
      name: "SystemMigration",
      component: () => import("@/manage/migration/view/Migration.vue").catch(catchImport),
      requireAuth: true,
    },
  },
  Native: {
    AppSettings: {
      path: "/native/settings",
      name: "AppSettings",
      component: () => import("@/native/view/AppSettings.vue").catch(catchImport),
      requireAuth: true,
    },
    OldAppSettings: {
      path: "/native/old-settings",
      name: "OldAppSettings",
      component: () => import("@/native/view/OldAppSettings.vue").catch(catchImport),
      requireAuth: true,
    },
  },
  VerifyUser: {
    Set: {
      List: {
        path: "/verify-user/:organizationId",
        name: "SetList",
        component: () => import("@/verifyUser/view/setList/SetList.vue").catch(catchImport),
        build(organizationId: string) { return `/verify-user/${organizationId}` },
        props: true,
        requireAuth: true,
      },
      Detail: {
        path: "/verify-user/set/:setId",
        name: "SetDetail",
        component: () => import("@/verifyUser/view/setDetail/SetDetail.vue").catch(catchImport),
        build(setId: string) { return `/verify-user/set/${setId}` },
        props: true,
        requireAuth: true,
      },
    }
  },
  WechatWork: {
    BindUser: {
      path: "/wechatWork/bindUser",
      name: "wechatWorkBindUser",
      component: () => import("@/wechatwork/view/BindUser.vue").catch(catchImport),
      single: true,
    },
    BindOrganization: {
      path: "/wechatWork/bindOrganization",
      name: "wechatWorkBindOrganization",
      component: () => import("@/wechatwork/view/BindOrganization.vue").catch(catchImport),
      single: true,
    },
    Signin: {
      path: "/wechatWork/signin",
      name: "wechatWorkSignin",
      component: () => import("@/wechatwork/view/WechatWorkSignin.vue").catch(catchImport),
      single: true,
    }
  },
  Feedback: {
    List: {
      path: "/feedback/list",
      name: "FeedbackList",
      component: () => import("@/feedback/view/FeedbackList.vue").catch(catchImport),
      props: true,
      requireAuth: true,
    }
  },
  Gjt: {
    Login: {
      path: "/gjt-login",
      name: "gjt-login",
      component: () => import("@/gjt/view/login/GjtLogin.vue").catch(catchImport),
      single: true,
    }
  }
}

export default Routes
