import { useEnvStore } from "@/store/EnvStore"
import {
  computed,
} from "vue"

export interface CustomRowOption {
  getKeys: () => Array<string>
  setKeys: (ids: Array<string>) => any
  rowId: (row: any) => string
}

export function clickRow(option: CustomRowOption) {
  return (row: any) => {
    return {
      style: 'cursor: pointer;',
      onClick: (e: any) => {
        if (e?.srcElement?.className?.indexOf?.("n-checkbox-box") >= 0) {
          return
        }
        const keys = JSON.parse(JSON.stringify(option.getKeys())) as Array<string>
        const id = option.rowId(row)
        if (keys.indexOf(id) >= 0) {
          keys.splice(keys.indexOf(id), 1)
        } else {
          keys.push(id)
        }
        option.setKeys(keys)
      }
    }
  }
}

export function useTableStyle() {
  const envStore = useEnvStore()
  return computed(() => {
    return {
      "min-height": `${Math.max(envStore.size.panelHeight, 300)}px`,
      "width": `100%`
    }
  })
}

export function useScrollX(columns: any) {
  return computed(() => {
    if (Array.isArray(columns)) {
      return calScrollX(columns)
    } else {
      return calScrollX(columns.value)
    }
  })
}

export function calScrollX(columns: Array<any>) {
  let width = 0
  let resizable = false
  for (const col of columns) {
    if (col.resizable) {
      resizable = true
    }
    if (Array.isArray(col.children)) {
      const childrenWidth = calScrollX(col.children)
      if (childrenWidth) {
        width += childrenWidth
        resizable = true
      }      
    } else {
      if (col.maxWidth) {
        width += parseInt(`${col.maxWidth}`)
      } else if (col.width) {
        width += parseInt(`${col.width}`)
      } else if (col.minWidth) {
        width += parseInt(`${col.minWidth}`)
      }
    } 
  }
  if (resizable) {
    return width
  } else {
    return width
  }
}