<template>
  <div>
    <div v-if="loading" class="loading-container">
      <n-skeleton text round :style="loadingStyle" />
      <n-skeleton v-if="props.multiLine" text :repeat="1" width="60%" round :style="loadingStyle" />
    </div>
    <div v-if="!loading && !state.edit" class="clickable-container flex-row-nowrap-start-start-center" @click="onEdit">
      <span v-if="text.length > 0" :class="textClazz">{{ text }}</span>
      <span v-else class="ec-hint text-empty">{{ emptyText }}</span>
    </div>
    <div v-if="!loading && state.edit" class="edit-container"
      :style="inputContainerStyle">
      <n-input ref="ip" :type="multiLine ? 'textarea' : 'text'" :class="textClazz" :value="state.draft"
        :autosize="autoSize" @update:value="onUpdateDraft" @blur="onInputBlur"></n-input>
      <div v-if="!hideSave" :class="actionContainerClazz">
        <span class="button flex-row-nowrap-center-center-center" @click="onSaveText" @mousedown="onMousedown"
          @mouseup="onMouseup">
          <ec-icon name="common/check" :size="16"></ec-icon>
        </span>
        <div class="button flex-row-nowrap-center-center-center" style="margin-left: 8px" @click="onCancel"
          @mousedown="onMousedown" @mouseup="onMouseup">
          <ec-icon name="common/cross" :size="16"></ec-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  computed,
  nextTick,
  reactive,
  ref,
  watch,
} from "vue";
import message from "@/util/message"
import { InputInst } from 'naive-ui'

const props = withDefaults(defineProps<{
  text: string,
  emptyText?: string,
  multiLine?: boolean,
  head?: string,
  save?: (newContent: string) => Promise<void>,
  inlineAction?: boolean
  loading?: boolean
}>(), {
  text: "",
  emptyText: "",
  multiLine: false,
  head: "",
  inlineAction: false,
  loading: false
})
const emits = defineEmits<{
  (e: "update:text", value: string): void
}>()

const ip = ref<InputInst | null>(null)

const state = reactive({
  edit: false,
  draft: props.text,
  clickSave: false
})

const loadingStyle = computed(() => {
  switch (props.head) {
    case "h1":
      return { "height": "32px" }
    default:
      return { "height": "16px" }
  }
})

const autoSize = computed(() => {
  if (props.multiLine) {
    return { minRows: 3, maxRows: 10 }
  } else {
    return undefined
  }
})

const textClazz = computed(() => {
  let result = {} as any
  if (props.head == "h1") {
    result["text-h1"] = true
  } else {
    result["text"] = true
  }
  if (props.multiLine && props.inlineAction) {
    result["text-multiline-inline-action"] = true
  }
  return result
})

const inputContainerStyle = computed(() => {
  if (props.head == "h1") {
    return {
      "min-height": "56px"
    }
  } else {
    return {
    }
  }
})

const actionContainerClazz = computed(() => {
  let result = {
    "flex-row-nowrap-start-start-center": true
  } as any
  let inline = ""
  if (props.inlineAction) {
    inline = "-inline"
  }
  let multiline = ""
  if (props.multiLine) {
    multiline = "-mutiline"
  }
  result[`action-container${multiline}${inline}`] = true
  return result
})



const hideSave = computed(() => props.save == undefined)

const onUpdateDraft = (value: string) => {
  state.draft = value
  if (props.save == undefined) {
    emits("update:text", value)
  }
}

const onEdit = () => {
  state.edit = true
  nextTick(() => {
    ip.value?.focus()
  })
}

const onSaveText = async () => {
  if (props.save) {
    try {
      await props.save(state.draft)
      emits("update:text", state.draft)
      state.edit = false
    } catch (error: any) {
      message.networkError(error)
    }
  } else {
    emits("update:text", state.draft)
    state.edit = false
  }
}

const onCancel = () => {
  state.draft = props.text
  state.edit = false
}

const onMousedown = () => {
  state.clickSave = true
}

const onMouseup = () => {
  state.clickSave = false
}

const onInputBlur = () => {
  if (!state.clickSave) {
    state.edit = false
    state.draft = props.text
  }
}

watch(() => props.text, (v) => { state.draft = v })

</script>
<style scoped>
.loading-container {
  margin-left: -4px;
  min-width: 12px;
  min-height: 34px;
  padding: 4px 4px 4px 4px;
}

.clickable-container {
  margin-left: -4px;
  min-width: 12px;
  min-height: 34px;
  background-color: transparent;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
}

.clickable-container:hover {
  margin-left: -4px;
  min-width: 12px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.text {
  white-space: pre-wrap;
}

.text-h1 {
  white-space: pre-wrap;
  font-size: 30px;
  font-weight: 500;
  min-height: 48px;
}

.text-empty {
  font-size: 14px;
}

.text-multiline-inline-action {
  padding-bottom: 32px;
}

.edit-container {
  min-width:40px;
  margin-left: -12px;
  position: relative;
}

.action-container {
  position: absolute;
  bottom: -36px;
  right: 0px;
  z-index: 1;
}

.action-container-inline {
  margin-left: 12px;
}

.action-container-mutiline {
  position: absolute;
  bottom: -40px;
  right: 0px;
  z-index: 1;
}

.action-container-mutiline-inline {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 1;
}


.button {
  background-color: #fafafa;
  cursor: pointer;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  text-align: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
</style>