<template>
  <div class="container">
    <div class="flex-row-nowrap-start-start-center search-container">
      <n-input v-model:value="state.keyword" placeholder="关键字过滤"></n-input>
    </div>
    <n-scrollbar style="max-height: 300px;">
      <div class="option-container">
        <div class="option" v-for="opt in showOptions" key="id">
          <n-checkbox :checked="opt.checked" @update:checked="(checked: boolean) => onChecked(opt, checked)">{{ opt.text
          }}</n-checkbox>
        </div>
        <div v-if="showOptions.length == 0" class="ec-hint" style="padding-top: 8px; padding-bottom: 4px;">找不到包含该关键字的选项
        </div>
      </div>
    </n-scrollbar>
    <div style="background: rgba(31, 34, 37, 0.1); height: 1px; width: 100%; margin-top:8px;"></div>
    <div class="flex-row-nowrap-end-center-center action-container">
      <n-button size="small" style="margin-right: 8px;" @click="onReset">全部清除</n-button>
      <n-button size="small" type="primary" @click="onFilter">确认</n-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRef,
  useAttrs,
  useSlots,
  watch,
  h
} from "vue"
import { Option } from "@/components/model/Option"

const props = withDefaults(defineProps<{
  options?: Array<Option>,
  selectedIds: Array<string>,
  multiple?: boolean
}>(), {
  options: () => [] as Array<Option>,
  multiple: false
})
const emits = defineEmits<{
  (e: "filter"): void
  (e: "update:selectedIds", selectedIds: Array<string>, selectedTexts: Array<string>): void
}>()

const state = reactive({
  options: props.options,
  keyword: ""
})

const showOptions = computed(() => {
  if (state.keyword.length == 0) {
    return state.options
  } else {
    return state.options.filter(opt => opt.text.indexOf(state.keyword) >= 0)
  }
})

const onChecked = (opt: Option, checked: boolean) => {
  if (props.multiple) {
    opt.checked = checked
    let ids = [] as Array<string>
    let texts = [] as Array<string>
    for (const t of state.options) {
      if (t.checked) {
        ids.push(t.id)
        texts.push(t.text)
      }
    }
    emits("update:selectedIds", ids, texts)
    return
  } else {
    for (const t of state.options) {
      t.checked = false
    }
    if (checked) {
      opt.checked = true
      emits("update:selectedIds", [opt.id], [opt.text])
    } else {
      emits("update:selectedIds", [], [])
    }
  }
}

const onFilter = () => {
  emits("filter")
}

const onReset = () => {
  for (const t of state.options) {
    t.checked = false
  }
  emits("update:selectedIds", [], [])
  emits("filter")
}

const refreshCheckedState = () => {
  for (const opt of state.options) {
    opt.checked = props.selectedIds.indexOf(opt.id) >= 0
  }
}

watch(
  () => props.options,
  (value, oldValue) => {
    if (value) {
      state.options = JSON.parse(JSON.stringify(value))
      refreshCheckedState()
    } else {
      state.options = []
    }
  }
)

refreshCheckedState()

</script>
<style scoped>
.container {
  width: 400px;
}

.search-container {
  padding-bottom: 4px;
}

.option-container {}

.option {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}

.action-container {
  width: 400px;
  margin-top: 8px;
}

.button {
  height: 32px;
}
</style>
