<template>
  <div :style="containerStyle">
    <div :style="dividerStyle"></div>
  </div>
</template>
<script lang="ts" setup>
import {
  computed
} from "vue"

const props = withDefaults(defineProps<{
  mode?: "horizontal" | "vertical"
  margin?: number
  color?: string
}>(), {
  mode: "horizontal",
  margin: 0,
  color: "#dbdbdb"
})

const dividerStyle = computed(() => {
  if (props.mode == "vertical") {
    return {
      "width": "1px",
      "height": "100%",
      "background-color": props.color
    }
  } else {
    return {
      "width": "100%",
      "height": "1px",
      "background-color": props.color
    }
  }
})

const containerStyle = computed(()=> {
  if (props.mode == "vertical") {
    return {
      "height": "100%",
      "padding": `${props.margin}px 0px ${props.margin}px 0px`
    }
  } else {
    return {
      "width": "100%",
      "padding": `0px ${props.margin}px 0px ${props.margin}px`
    }
  }
})

</script>
<style scoped>
</style>
