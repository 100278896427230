import { createDiscreteApi, useMessage } from "naive-ui"
import { ValidationError } from "@/common/model/ErrorModel"
import * as Sentry from "@sentry/vue"

function printer(): any {
  const { message } = createDiscreteApi(["message"])
  return message
}

function info(content: string) {
  const message = printer()
  message.info(content)
}

function warning(content: string) {
  printer().warning(content)
}

function error(content: string) {
  printer().error(content)
}

function networkError(e: Error) {
  Sentry.captureException(e)
  const content = getNetworkErrorMessage(e)
  error(content)
}

function getNetworkErrorMessage(error: Error): string {
  if (error instanceof ValidationError) {
    return "输入参数有误"
  }
  const msg = error?.response?.data?.message;
  if (msg) {
    return msg
  } else {
    return "网络错误"
  }
}

export default {
  info,
  warning,
  error,
  networkError,
  parseNetworkError: getNetworkErrorMessage
}