import axios from "axios"
import { refreshToken } from "@/auth/api/AuthApi"
import router from "@/router"
import Routes from "@/router/Routes"
import { nextTick } from "vue"
import { useMainStore } from "@/store/main"
import { useUserStore } from "@/store/UserStore"
import { startsWith } from "@/util/StringUtils"

const apiUrl = (window as any).eightcatty.apiUrl
// console.log("api", apiUrl)

const request = axios.create({
  baseURL: apiUrl,
  timeout: 180000,
})

let pendingRequests = [] as any[];
let isTokenRefreshing = false;

const errorHandler = async (error: any) => {
  if (error.response) {
    const data = error.response.data;
    if (error.response.status === 401) {
      const config = error.response.config
      if (isTokenRefreshing) {
        return new Promise((resolve) => {
          pendingRequests.push(() => {
            resolve(request(config))
          })
        })
      }
      isTokenRefreshing = true
      try {
        await tryRefreshToken()
        pendingRequests.forEach((cb) => cb())
        pendingRequests = []
        return await request(config)
      } catch (refreshError: any) {
        pendingRequests = []
        nextTick(() => { jumpToSign() })
        throw (refreshError)
      }
    } else if (error.response.status == 580) {
      router.replace({ path: Routes.Common.License.path })
    }
  }
  return Promise.reject(error)
}

const tryRefreshToken = async () => {
  try {
    const refreshTokenValue = window.localStorage.getItem("refreshToken") as any
    const response = await refreshToken(refreshTokenValue)
    const userStore = useUserStore()
    userStore.refreshToken(`Bearer ${response.accessToken}`)
  } finally {
    isTokenRefreshing = false;
  }
}

const jumpToSign = async () => {
  const path = router.currentRoute.value.fullPath
  const mainStore = useMainStore()
  mainStore.setInitializing(false)
  //过滤并发的跳转
  if (startsWith(path, Routes.Auth.Login.path)) {
    return
  }
  router.replace({ path: Routes.Auth.Login.path, query: { redirect: encodeURIComponent(path) } })
}

request.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("accessToken");

  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, errorHandler);

request.interceptors.response.use((response) => {
  return response.data;
}, errorHandler);

export default request;

export { request as axios };
