<template>
  <div class="flex-row-nowrap-start-start-center search-container">
    <n-input-group>
      <n-input :value="keyword" @update:value="onChanged" placeholder="按关键字搜索" clearable @keyup="onKeyup"></n-input>
      <n-button @click="onFind">查找</n-button>
    </n-input-group>
  </div>
</template>
<script lang="ts" setup>
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRef,
  useAttrs,
  useSlots,
  watch,
  h
} from "vue"

const props = withDefaults(defineProps<{
  keyword: string
}>(), {
  keyword: ""
})
const emits = defineEmits<{
  (e: "find"): void
  (e: "update:keyword", keyword: string): void
}>()

const onChanged = (value: string) => {
  emits("update:keyword", value)
}

const onKeyup = (e: any) => {
  if (e.key == "Enter") {
    onFind()
  }
}

const onFind = () => {
  emits("find")
}

</script>
<style scoped>
.search-container {
  margin: -8px -14px -8px -14px;
  width: 300px;
}
</style>
