import { ApiResponse } from "@/common/model/ApiModel"
import { User } from "@/user/model/UserModel"
import request from "@/util/request"
import {
  LoginResponse,
  ResetPasswordParam,
  JoinParam,
  LoginParam,
  RefreshTokenResponse,
} from "@/auth/model/AuthApiModel"

export function join(param: JoinParam): Promise<User> {
  return request.post("/v3/auth/join", param)
}

export function login(param: LoginParam): Promise<LoginResponse> {
  return request.post("/v3/auth/login", param)
}

export function refreshToken(
  refreshToken: string
): Promise<RefreshTokenResponse> {
  return request.post("/v3/auth/token", { refreshToken })
}

export function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<ApiResponse> {
  return request.post(`/v3/auth/changePassword`, {
    oldPassword,
    newPassword,
  })
}

export function resetPassword(param: ResetPasswordParam) {
  return request.post(`/v3/auth/resetPassword`, param)
}
